<template>
    <painel titulo="RDStation - Parâmetros - Atualizar" icone="pi pi-cog" :refreshFunction="obterParametros">
        <div class="mb-4">
            <erros-box :erros="erros"></erros-box>
            <div class="formgrid grid p-fluid">
                <div class="field col-12 md:col-4">
                    <label class="required">Identificador</label>
                    <InputText type="text" v-model="identificador" @input="v$.identificador.$touch()" />
                    <small class="p-error" v-if="v$.identificador.$error">O campo identificador é obrigatório</small>
                </div>
                <div class="field col-12 md:col-4">
                    <label class="required">Descrição do Atendimento</label>
                    <InputText type="text" v-model="descricaoAtendimento" @input="v$.descricaoAtendimento.$touch()" />
                    <small class="p-error" v-if="v$.descricaoAtendimento.$error">O campo descrição do atendimento é obrigatório</small>
                </div>
                <div class="field col-12 md:col-4">
                    <label class="required">Data do Atendimento</label>
                    <InputText type="text" v-model="dataAtendimento" @input="v$.dataAtendimento.$touch()" />
                    <small class="p-error" v-if="v$.dataAtendimento.$error">O campo data do atendimento é obrigatório</small>
                </div>
                <div class="field col-12 md:col-4">
                    <label class="required">CPF</label>
                    <InputText type="text" v-model="cpf" @input="v$.cpf.$touch()" />
                    <small class="p-error" v-if="v$.cpf.$error">O campo CPF é obrigatório</small>
                </div>
                <div class="field col-12 md:col-4">
                    <label>Nome PF</label>
                    <InputText type="text" v-model="nomePF" />
                </div>
                <div class="field col-12 md:col-4">
                    <label>CNPJ</label>
                    <InputText type="text" v-model="cnpj" />
                </div>
                <div class="field col-12 md:col-4">
                    <label>Email</label>
                    <InputText type="text" v-model="email" />
                </div>
                <div class="field col-12 md:col-4">
                    <label>Telefone</label>
                    <InputText type="text" v-model="telefone" />
                </div>
                <div class="field col-12 md:col-4">
                    <label>Cidade</label>
                    <InputText type="text" v-model="cidade" />
                </div>
                <div class="field col-12 md:col-4">
                    <label>UF</label>
                    <InputText type="text" v-model="uf" />
                </div>
            </div>
        </div>
        <btn-voltar :history="true"></btn-voltar>
        <Button class="ml-2" label="Salvar" icon="pi pi-save" @click="confirmarSalvar()" :disabled="v$.$invalid" />
    </painel>
</template>

<script>
import Services from './services';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    props: {
        parametros: {
            type: Object,
        },
    },

    emits: ['obterParametrosAtualizados'],

    data() {
        return {
            erros: [],
            identificador: null,
            descricaoAtendimento: null,
            dataAtendimento: null,
            cpf: null,
            nomePF: null,
            cnpj: null,
            email: null,
            telefone: null,
            cidade: null,
            uf: null,
        };
    },

    validations() {
        return {
            identificador: { required },
            descricaoAtendimento: { required },
            dataAtendimento: { required },
            cpf: { required },
        };
    },

    methods: {
        obterParametros() {
            this.$store.dispatch('addRequest');
            Services.obterParametros().then((response) => {
                if (response?.success) {
                    this.preencherFormulario(response.data);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'RDStation',
                        detail: 'Erro ao obter os parâmetros',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        preencherFormulario(parametros) {
            this.identificador = parametros.identificador;
            this.descricaoAtendimento = parametros.descricaoAtendimento;
            this.dataAtendimento = parametros.dataAtendimento;
            this.cpf = parametros.cpf;
            this.nomePF = parametros.nomePF;
            this.cnpj = parametros.cnpj;
            this.email = parametros.email;
            this.telefone = parametros.telefone;
            this.cidade = parametros.cidade;
            this.uf = parametros.uf;
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja salvar os parâmetros?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            this.$store.dispatch('addRequest');
            let parametrosDto = {
                identificador: this.identificador,
                descricaoAtendimento: this.descricaoAtendimento,
                dataAtendimento: this.dataAtendimento,
                cpf: this.cpf,
                nomePF: this.nomePF,
                cnpj: this.cnpj,
                email: this.email,
                telefone: this.telefone,
                cidade: this.cidade,
                uf: this.uf,
            };
            Services.atualizar(parametrosDto).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'RDStation',
                        detail: 'Parâmetros atualizados com sucesso',
                        life: 3000,
                    });
                    this.toDetalhar();
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar() {
            this.$emit('obterParametrosAtualizados');
            this.$router.push({
                name: 'RdStation_Parametros',
            });
        },
    },

    computed: {
        // mostrarBtnIntegrar() {
        //     return this.activeIndex == 0 && this.$temAcessoView('RD-LEADS-01');
        // },
    },

    mounted() {
        if (this.parametros) {
            this.preencherFormulario(this.parametros);
        }
    },
};
</script>
