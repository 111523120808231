import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_RDSTATION_PORT}${process.env.VUE_APP_API_PATH}/rdstation`;
const urlBase = `${api}/parametros`;

export default {
    obterParametros() {
        return axiosJwt.get(`${urlBase}`);
    },

    atualizar(parametros) {
        return axiosJwt.post(`${urlBase}/atualizar`, parametros);
    },
};
